import React, { useEffect } from "react";
import MediaHeading from "./MediaHeading";
import LatestAlbums from "./LatestAlbums.jsx";
import LatestAudio from "./LatestAudio.jsx";
import LatestVideos from "./LatestVideos.jsx";
import LatestGallery from "./LatestGallery.jsx";
import "./styles/Latest.css";

export default function Latest({
  playingType,
  setPlayingType,
  setAlbumView,
  section,
  currentMedia,
  setCurrentMedia,

  /* Tracks */
  toMinutes,
  openPlayer,
  setList,
  playing,
  currentTime,
  setPlaying,
  currentPlaying,
  setCurrentPlaying,
  tracks,
  albums,

  /* Videos */
  videoIndex,
  setVideoIndex,
  videoPlaying,
  setVideoPlaying,
  videos,
  mouseDown,
  setViewAllVideos,

  /* Gallery */
  images,
  setGalleryIndex,
  setGalleryOpen,
  setViewAllImages,
}) {
  useEffect(() => {
    switch (section) {
      case "albums":
        setTimeout(() => {
          window.scrollBy(
            0,
            document.querySelector(".latest-albums").getBoundingClientRect()
              .top - 10
          );
        }, 100);
        break;
      case "audio":
        setTimeout(() => {
          window.scrollBy(
            0,
            document.querySelector(".latest-audio").getBoundingClientRect()
              .top - 10
          );
        }, 100);
        break;
      case "videos":
        setTimeout(() => {
          window.scrollBy(
            0,
            document.querySelector(".latest-videos").getBoundingClientRect()
              .top - 10
          );
        }, 100);
        break;
      case "gallery":
        setTimeout(() => {
          window.scrollBy(
            0,
            document.querySelector(".latest-gallery").getBoundingClientRect()
              .top - 10
          );
        }, 100);
        break;
      default:
        break;
    }
  }, [section]);

  return (
    <div className="media-latest">
      <section className="latest-albums">
        <MediaHeading
          href="/media?=latest&albums"
          link={{
            url: "/media?=albums",
            text: "VIEW ALL",
            action: (e) => {
              e.preventDefault();
              setCurrentMedia("albums");
              window.history.pushState("", "", "/media?=albums");
              window.scrollTo({ top: 0, left: 0, behavior: "instant" });
            },
          }}
        >
          LATEST ALBUMS
        </MediaHeading>
        <LatestAlbums
          albums={albums}
          setAlbumView={setAlbumView}
          setCurrentMedia={setCurrentMedia}
          toMinutes={toMinutes}
          tracks={tracks}
          currentPlaying={currentPlaying}
          setList={setList}
          setCurrentPlaying={setCurrentPlaying}
          setPlayingType={setPlayingType}
          setPlaying={setPlaying}
          openPlayer={openPlayer}
          setViewAllVideos={setViewAllVideos}
          setViewAllImages={setViewAllImages}
        />
      </section>
      <section className="latest-audio">
        <MediaHeading
          href="/media?=latest&audio"
          link={{
            url: `/media?id=a${(albums.find((album) => album.complete).id + 1)
              .toString()
              .padStart(2, "0")}&audio&view-all`,
            text: "VIEW ALL",
            action: (e) => {
              e.preventDefault();
              window.history.pushState(
                "",
                "",
                `/media?id=a${(albums.find((album) => album.complete).id + 1)
                  .toString()
                  .padStart(2, "0")}&audio&view-all`
              );
              setAlbumView(albums.find((album) => album.complete).id);
              setCurrentMedia("album");
              setTimeout(() => {
                window.scrollTo({ top: 0, left: 0, behavior: "instant" });
                window.scrollBy(
                  0,
                  document
                    .querySelector("#album-tracks")
                    .getBoundingClientRect().top - 10
                );
              }, 100);
            },
          }}
        >
          LATEST AUDIO
        </MediaHeading>
        <LatestAudio
          toMinutes={toMinutes}
          openPlayer={openPlayer}
          setList={setList}
          playing={playing}
          currentTime={currentTime}
          setPlaying={setPlaying}
          currentPlaying={currentPlaying}
          setCurrentPlaying={setCurrentPlaying}
          tracks={tracks}
          albums={albums}
          viewAlbum={setAlbumView}
          setCurrentMedia={setCurrentMedia}
        />
      </section>
      <section className="latest-videos">
        <MediaHeading
          href="/media?=latest&videos"
          link={{
            url: `/media?id=a${(albums.find((album) => album.complete).id + 1)
              .toString()
              .padStart(2, "0")}&videos&view-all`,
            text: "VIEW ALL",
            action: (e) => {
              e.preventDefault();
              window.history.pushState(
                "",
                "",
                `/media?id=a${(albums.find((album) => album.complete).id + 1)
                  .toString()
                  .padStart(2, "0")}&videos&view-all`
              );
              setAlbumView(albums.find((album) => album.complete).id);
              setViewAllVideos(true);
              setCurrentMedia("album");
              setTimeout(() => {
                window.scrollTo({ top: 0, left: 0, behavior: "instant" });
                window.scrollBy(
                  0,
                  document
                    .querySelector("#album-videos")
                    .getBoundingClientRect().top - 10
                );
              }, 100);
            },
          }}
        >
          LATEST VIDEOS
        </MediaHeading>
        <LatestVideos
          index={videoIndex}
          setIndex={setVideoIndex}
          playing={videoPlaying}
          setPlaying={setVideoPlaying}
          playingType={playingType}
          setPlayingType={setPlayingType}
          toMinutes={toMinutes}
          videos={videos}
          albums={albums}
          mouseDown={mouseDown}
          viewAlbum={setAlbumView}
          setCurrentMedia={setCurrentMedia}
        />
      </section>
      <section className="latest-gallery">
        <MediaHeading
          href="/media?=latest&gallery"
          link={{
            url: `/media?id=a${(albums.find((album) => album.complete).id + 1)
              .toString()
              .padStart(2, "0")}&gallery&view-all`,
            text: "VIEW ALL",
            action: (e) => {
              e.preventDefault();
              window.history.pushState(
                "",
                "",
                `/media?id=a${(albums.find((album) => album.complete).id + 1)
                  .toString()
                  .padStart(2, "0")}&gallery&view-all`
              );
              setAlbumView(albums.find((album) => album.complete).id);
              setViewAllImages(true);
              setCurrentMedia("album");
              setTimeout(() => {
                window.scrollTo({ top: 0, left: 0, behavior: "instant" });
                window.scrollBy(
                  0,
                  document
                    .querySelector("#album-gallery")
                    .getBoundingClientRect().top - 10
                );
              }, 100);
            },
          }}
        >
          LATEST GALLERY
        </MediaHeading>
        <LatestGallery
          images={images}
          albums={albums}
          viewAlbum={setAlbumView}
          setCurrentMedia={setCurrentMedia}
          setGalleryIndex={setGalleryIndex}
          setGalleryOpen={setGalleryOpen}
        />
      </section>
    </div>
  );
}
