import React from "react";
import { SplideSlide } from "@splidejs/react-splide";
import MediaCarousel from "./MediaCarousel.jsx";
import Album from "./Album.jsx";
import "./styles/LatestAlbums.css";

export default function LatestAlbums({
  albums,
  setAlbumView,
  setCurrentMedia,
  toMinutes,
  tracks,
  currentPlaying,
  setList,
  setCurrentPlaying,
  setPlayingType,
  setPlaying,
  openPlayer,
  setViewAllVideos,
  setViewAllImages,
}) {
  return (
    <div id="latest-albums">
      {albums[0] !== "ALBUMS_NOT_YET_LOADED" ? (
        <>
          <h2 className="subtitle">PERSONAL</h2>
          <MediaCarousel
            activeSlide={
              currentPlaying || currentPlaying === 0
                ? albums
                    .filter((album) => album.personal)
                    .findIndex(
                      (album) =>
                        album.id ===
                        tracks.find((track) => track.id === currentPlaying)
                          .album
                    ) < 0
                  ? 0
                  : albums
                      .filter((album) => album.personal)
                      .findIndex(
                        (album) =>
                          album.id ===
                          tracks.find((track) => track.id === currentPlaying)
                            .album
                      )
                : 0
            }
          >
            {albums
              .filter((album) => album.personal)
              .map((album) => (
                <SplideSlide>
                  <Album
                    id={album.id}
                    name={album.title}
                    mediumImage={album.mediumImagePath}
                    image={album.imagePath}
                    composer={album.composer}
                    complete={album.complete}
                    height="big"
                    viewAlbum={() => {
                      setAlbumView(album.id);
                      setCurrentMedia("album");
                    }}
                    toMinutes={toMinutes}
                    tracks={tracks}
                    currentPlaying={currentPlaying}
                    setList={setList}
                    setCurrentPlaying={setCurrentPlaying}
                    setPlayingType={setPlayingType}
                    setPlaying={setPlaying}
                    openPlayer={openPlayer}
                    setViewAllVideos={setViewAllVideos}
                    setViewAllImages={setViewAllImages}
                  />
                </SplideSlide>
              ))}
          </MediaCarousel>
          <h2 className="subtitle">PROJECTS</h2>
          <MediaCarousel
            activeSlide={
              currentPlaying || currentPlaying === 0
                ? albums
                    .filter((album) => !album.personal)
                    .findIndex(
                      (album) =>
                        album.id ===
                        tracks.find((track) => track.id === currentPlaying)
                          .album
                    ) < 0
                  ? 0
                  : albums
                      .filter((album) => !album.personal)
                      .findIndex(
                        (album) =>
                          album.id ===
                          tracks.find((track) => track.id === currentPlaying)
                            .album
                      )
                : 0
            }
          >
            {albums
              .filter((album) => !album.personal)
              .map((album) => (
                <SplideSlide>
                  <Album
                    id={album.id}
                    name={album.title}
                    mediumImage={album.mediumImagePath}
                    image={album.imagePath}
                    composer={album.composer}
                    complete={album.complete}
                    height="small"
                    viewAlbum={() => {
                      setAlbumView(album.id);
                      setCurrentMedia("album");
                    }}
                    toMinutes={toMinutes}
                    tracks={tracks}
                    currentPlaying={currentPlaying}
                    setList={setList}
                    setCurrentPlaying={setCurrentPlaying}
                    setPlayingType={setPlayingType}
                    setPlaying={setPlaying}
                    openPlayer={openPlayer}
                    setViewAllVideos={setViewAllVideos}
                    setViewAllImages={setViewAllImages}
                  />
                </SplideSlide>
              ))}
          </MediaCarousel>
        </>
      ) : (
        <div className="album-loader">
          <div className="loading-album">
            <div className="loading-album_image"></div>
            <div className="loading-album_title"></div>
            <div className="loading-album_composer"></div>
          </div>
          <div className="loading-album">
            <div className="loading-album_image"></div>
            <div className="loading-album_title"></div>
            <div className="loading-album_composer"></div>
          </div>
          <div className="loading-album">
            <div className="loading-album_image"></div>
            <div className="loading-album_title"></div>
            <div className="loading-album_composer"></div>
          </div>
          <div className="loading-album">
            <div className="loading-album_image"></div>
            <div className="loading-album_title"></div>
            <div className="loading-album_composer"></div>
          </div>
        </div>
      )}
    </div>
  );
}
