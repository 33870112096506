import React from "react";
import "./styles/FAQ.css";

export default function FAQ({ question, children: answer }) {
  let wait = false;

  function toggleFAQ(event) {
    if (!wait) {
      wait = true;
      const question = event.currentTarget;

      if (question.getAttribute("data-open") === "false") {
        openFAQ(question);
      } else {
        closeFAQ(question);
      }

      setTimeout(() => (wait = false), 400);
    }
  }

  function openFAQ(element) {
    const faq = element.parentElement;
    const icon = element.querySelector("svg");
    const answer = faq.querySelector(".answer");
    const currentOpenFAQ = document.querySelector(".question[data-open=true]");

    // close other open FAQs
    if (currentOpenFAQ) {
      closeFAQ(currentOpenFAQ);
    }

    icon.style.transform = "rotate(0deg)";
    element.setAttribute("data-open", "true");
    faq.style.height = faq.clientHeight + answer.clientHeight + 15 + "px";
  }

  function closeFAQ(element) {
    const faq = element.parentElement;
    const icon = element.querySelector("svg");

    icon.style.transform = "rotate(-225deg)";
    element.setAttribute("data-open", "false");
    faq.removeAttribute("style");
  }

  return (
    <div className="faq">
      <p className="question" onClick={toggleFAQ} data-open="false">
        {question}
        <button>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16px"
            viewBox="0 0 24 24"
            width="16px"
            fill="currentColor"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
          </svg>
        </button>
      </p>
      <p className="answer">{answer}</p>
    </div>
  );
}
