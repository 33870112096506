import React from "react";
import { AudioCollection, AudioTrack } from "./AudioCollection.jsx";
import "./styles/LatestAudio.css";

export default function LatestAudio({
  toMinutes,
  openPlayer,
  setList,
  playing,
  currentTime,
  setPlaying,
  currentPlaying,
  setCurrentPlaying,
  tracks,
  albums,
  viewAlbum,
  setCurrentMedia,
}) {

  return (
    <div id="latest-audio">
      <AudioCollection>
        {tracks[0] !== "TRACKS_NOT_YET_LOADED" &&
        albums[0] !== "ALBUMS_NOT_YET_LOADED" ? (
          tracks.slice(0, 10).map((track, index) => (
            <AudioTrack
              id={track.id}
              index={index + 1}
              title={track.title}
              album={albums[track.album].title}
              mood={track.mood}
              duration={[
                currentPlaying === track.id
                  ? toMinutes(currentTime)
                  : toMinutes(0),
                toMinutes(track.duration),
              ].join(" / ")}
              rating={{ positive: 127, negative: 21 }}
              openPlayer={openPlayer}
              playing={playing}
              setPlaying={setPlaying}
              currentPlaying={currentPlaying}
              setCurrentPlaying={setCurrentPlaying}
              list={tracks.map((track) => track.id)}
              setList={setList}
              tracks={tracks}
              albumID={track.album}
              viewAlbum={() => {
                viewAlbum(track.album);
                setCurrentMedia("album");
              }}
              date={track.date}
              views={track.views}
              description={track.description}
              copyright={track.copyright}
            />
          ))
        ) : (
          <>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
          </>
        )}
      </AudioCollection>
    </div>
  );
}
