import React from "react";
import { SplideSlide } from "@splidejs/react-splide";
import "./styles/Testimonial.css";

export default function Tesimonial({
  name,
  email,
  occupation,
  date,
  children,
  rating,
}) {
  const stars = [];
  const fullStars = Math.floor(rating);
  const emptyStars = Math.floor(5 - rating);
  const halfStars = 5 - (fullStars + emptyStars);

  for (let i = 0; i < fullStars; i++) {
    stars.push(
      <img
        key={`full${i}`}
        className="star"
        alt="full star"
        src="/images/about-icon-star-full.svg"
        width={20}
        height={20}
      />
    );
  }

  for (let i = 0; i < halfStars; i++) {
    stars.push(
      <img
        key={`half${i}`}
        className="star"
        alt="half star"
        src="/images/about-icon-star-half.svg"
        width={20}
        height={20}
      />
    );
  }

  for (let i = 0; i < emptyStars; i++) {
    stars.push(
      <img
        key={`empty${i}`}
        className="star"
        alt="empty star"
        src="/images/about-icon-star-empty.svg"
        width={20}
        height={20}
      />
    );
  }

  return (
    <SplideSlide>
      <div className="testimonial">
        <div className="testimonial-text">
          <img
            src="/images/about-testimonials-quote.png"
            alt="quote"
            width="20"
            height="20"
          />
          {children}
          <img
            src="/images/about-testimonials-quote.png"
            alt="quote"
            width="20"
            height="20"
          />
          <div className="testimonial-rating">{stars}</div>
          <span className="testimonial-date">{date}</span>
        </div>
        <a href={`mailto:${email}`} className="testimonial-name">
          {name}
        </a>
        <span className="testimonial-title">{occupation}</span>
        <div className="testimonial-progress"></div>
      </div>
    </SplideSlide>
  );
}
