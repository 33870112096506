import React, { useState, useRef, useEffect } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "./styles/MediaCarousel.css";

export default function MediaCarousel({activeSlide = false, children}) {
  const splideRef = useRef();
  const [wait, setWait] = useState(false);

  function move(position) {
    if (!wait) {
      try {
        splideRef.current.splide.go(`${typeof(position) === "number" ? `>${position}` : position}`);
        setWait(true);
        setTimeout(() => setWait(false), 800);
      } catch {
        console.error("Splide not initialised");
      }
    }
  }

  const fillerSlides = [];
  const extraCount = children.length % 4;

  if (extraCount !== 4) {
    for (let i = 0; i < (4 - extraCount); i++) {
      fillerSlides.push(
        <SplideSlide>
          <div className="album" style={{ pointerEvents: "none" }}></div>
        </SplideSlide>
      );
    }
  }

  useEffect(() => {
    if (activeSlide !== false) {
      try {
        splideRef.current.splide.go(Math.floor((activeSlide)/4) * 4);
      } catch {
        console.error("Splide not initialised");
      }
    }
  }, [activeSlide]);

  return(
    <div className="media-carousel">
      <button className="albums-arrow left" onClick={() => move("<")}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="66"
          viewBox="0 0 35 66"
          fill="none"
          stroke="#505050"
        >
          <path d="M34 65L2 33L34 1" strokeWidth="2" />
        </svg>
      </button>
      <Splide
        ref={splideRef}
        options={{
          type: "loop",
          speed: 800,
          width: 1208,
          perPage: 4,
          perMove: 4,
          gap: "0",
          pagination: false,
          arrows: false,
          cover: false,
          drag: false,
          waitForTransition: false,
          autoplay: false,
          focus: 0,
        }}
      >
        {children}
        {fillerSlides}
      </Splide>
      <button className="albums-arrow right" onClick={() => move(">")}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="66"
          viewBox="0 0 35 66"
          fill="none"
          stroke="#505050"
        >
          <path d="M1 1L33 33L1 65" strokeWidth="2" />
        </svg>
      </button>
      <div className="pages">
        {children.filter((element, index) => (index % 4 === 0)).map((element, index) => (
          <button
            key={index}
            data-active={splideRef.current ? Math.ceil((splideRef.current.splide.index / 4)) === index : index === 0}
            className="page"
            onClick={() => move(index)}
          >
            PAGE {index+1}
          </button>
        ))}
      </div>
    </div>
  );
}

