import React, { useEffect, useState } from "react";
import "./styles/Search.css"

export default function Search({ query, results, albums, setCurrentMedia, viewAlbum }) {
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    query(searchQuery);
  }, [query, searchQuery]);

  return (
    <div className="search">
      <input
        type="search"
        id="search"
        placeholder="SEARCH"
        autoComplete="off"
        onInput={(e) => setSearchQuery(e.target.value.toLowerCase().trim())}
      />
      <div
        className="clear"
        onClick={() => {
          document.querySelector("#search").value = "";
          setSearchQuery("");
        }}
        title="Clear"
      ></div>
      <div className="results" data-visible={searchQuery !== ""}>
        {results.albums || results.tracks || results.videos ? (
          <>
            {results.albums ? (
              <>
                <div>
                  <h4>ALBUMS</h4>
                  {results.albums.map((album) => (
                    <div className="result" key={album.id}>
                      <div className="left">
                        <img
                          src={album.tinyIconPath}
                          alt={album.title}
                          height="40"
                          draggable="false"
                        />
                        <div className="details">
                          {/*eslint-disable-next-line*/}
                          <a
                            href={`/media?id=a${(album.id + 1)
                              .toString()
                              .padStart(2, "0")}`}
                            className="title"
                            onMouseDown={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              window.history.pushState(
                                "",
                                "",
                                `/media?id=a${(album.id + 1)
                                  .toString()
                                  .padStart(2, "0")}`
                              );
                              setCurrentMedia("album");
                              viewAlbum(album.id);
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                            onMouseUp={() =>
                              document.querySelector("#search").blur()
                            }
                          >
                            {album.title}
                          </a>
                          {/*eslint-disable-next-line*/}
                          <a href="#" className="subtitle">
                            {album.composer}
                          </a>
                        </div>
                      </div>
                      <div className="right">
                        <span>RELEASED ON</span>
                        <span>JUNE 28TH, 2024</span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : null}
            {results.tracks ? (
              <>
                <div>
                  <h4>TRACKS</h4>
                  {results.tracks.map((track) => (
                    <div className="result" key={track.id}>
                      <div className="left">
                        <img
                          src={
                            albums[
                              albums.findIndex(
                                (album) => album.id === track.album
                              )
                            ].tinyIconPath
                          }
                          alt={
                            albums[
                              albums.findIndex(
                                (album) => album.id === track.album
                              )
                            ].title
                          }
                          height="40"
                          draggable="false"
                        />
                        <div className="details">
                          {/*eslint-disable-next-line*/}
                          <a href="#" className="title">
                            {track.title}
                          </a>
                          {/*eslint-disable-next-line*/}
                          <a
                            href={`/media?id=a${(track.album + 1)
                              .toString()
                              .padStart(2, "0")}`}
                            className="subtitle"
                            onMouseDown={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              window.history.pushState(
                                "",
                                "",
                                `/media?id=a${(track.album + 1)
                                  .toString()
                                  .padStart(2, "0")}`
                              );
                              setCurrentMedia("album");
                              viewAlbum(track.album);
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                            onMouseUp={() =>
                              document.querySelector("#search").blur()
                            }
                          >
                            {
                              albums[
                                albums.findIndex(
                                  (album) => album.id === track.album
                                )
                              ].title
                            }
                          </a>
                        </div>
                      </div>
                      <div className="right">
                        <span>RELEASED ON</span>
                        <span>JUNE 28TH, 2024</span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : null}
            {results.videos ? (
              <>
                <div>
                  <h4>VIDEOS</h4>
                  {results.videos.map((video) => (
                    <div className="result" key={video.id}>
                      <div className="left">
                        <img
                          src={
                            albums[
                              albums.findIndex(
                                (album) => album.id === video.album
                              )
                            ].tinyIconPath
                          }
                          alt={
                            albums[
                              albums.findIndex(
                                (album) => album.id === video.album
                              )
                            ].title
                          }
                          height="40"
                          draggable="false"
                        />
                        <div className="details">
                          {/*eslint-disable-next-line*/}
                          <a href="#" className="title">
                            {video.title}
                          </a>
                          {/*eslint-disable-next-line*/}
                          <a
                            href={`/media?id=a${(video.album + 1)
                              .toString()
                              .padStart(2, "0")}`}
                            className="subtitle"
                            onMouseDown={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              window.history.pushState(
                                "",
                                "",
                                `/media?id=a${(video.album + 1)
                                  .toString()
                                  .padStart(2, "0")}`
                              );
                              setCurrentMedia("album");
                              viewAlbum(video.album);
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                            onMouseUp={() =>
                              document.querySelector("#search").blur()
                            }
                          >
                            {
                              albums[
                                albums.findIndex(
                                  (album) => album.id === video.album
                                )
                              ].title
                            }
                          </a>
                        </div>
                      </div>
                      <div className="right">
                        <span>RELEASED ON</span>
                        <span>JUNE 28TH, 2024</span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </>
        ) : (
          <div>
            <div className="non">NO RESULTS FOUND</div>
          </div>
        )}
      </div>
    </div>
  );
}
