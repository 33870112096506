import React, { useState } from "react";
import MediaHeading from "../MediaHeading";
import PlayingVideo from "../PlayingVideo";
import NextVideos from "../NextVideos";
import "./styles/Videos.css";
import Video from "../Video";

export default function Videos({
  videos,
  id,
  index,
  setIndex,
  playing,
  setPlaying,
  albums,
  playingType,
  setPlayingType,
  toMinutes,
  mouseDown,
  viewAlbum,
  setCurrentMedia,
  requestScrollToVideos,
  requestVideoPlay,
  complete,
  viewAll = false,
  setViewAll,
}) {
  const [videoM, setVideoM] = useState(null);

  return (
    <section id="album-videos">
      <MediaHeading
        href={`/media?id=a${(id + 1).toString().padStart(2, "0")}&videos`}
        link={{
          url:
            complete && viewAll
              ? `/media?id=a${(albums.find((album) => album.complete).id + 1)
                  .toString()
                  .padStart(2, "0")}&videos`
              : `/media?id=a${(albums.find((album) => album.complete).id + 1)
                  .toString()
                  .padStart(2, "0")}&videos&view-all`,
          text: complete && viewAll ? "VIEW LESS" : "VIEW ALL",
          action: (e) => {
            e.preventDefault();
            window.history.pushState(
              "",
              "",
              complete && viewAll
                ? `/media?id=a${(albums.find((album) => album.complete).id + 1)
                    .toString()
                    .padStart(2, "0")}&videos`
                : `/media?id=a${(albums.find((album) => album.complete).id + 1)
                    .toString()
                    .padStart(2, "0")}&videos&view-all`
            );
            if (complete) {
              setViewAll(!viewAll);
            } else {
              setViewAll(true);
              viewAlbum(albums.find((album) => album.complete).id);
              setCurrentMedia("album");
            }
            setTimeout(() => {
              window.scrollBy(
                0,
                document.querySelector("#album-videos").getBoundingClientRect()
                  .top - 10
              );
            }, 100);
          },
        }}
      >
        VIDEOS
      </MediaHeading>
      {videos[0] !== "VIDEOS_NOT_YET_LOADED" &&
      videos.length !== 0 &&
      albums[0] !== "ALBUMS_NOT_YET_LOADED" ? (
        <>
          <PlayingVideo
            id={videos[index].id}
            length={videos.length}
            playingType={playingType}
            setPlayingType={setPlayingType}
            videoM={videoM}
            setVideoM={setVideoM}
            playing={playing}
            setPlaying={setPlaying}
            videoCount={videos.length}
            toMinutes={toMinutes}
            index={index}
            setIndex={setIndex}
            title={videos[index].title}
            description={videos[index].description}
            link={videos[index].link}
            album={albums[videos[index].album].title}
            copyright={`PROPERTY OF<br />${videos[index].copyright}`}
            thumbnail={videos[index].thumbnail}
            youtube={videos[index].youtube}
            views={videos[index].views}
            date={videos[index].date}
            rating={videos[index].rating}
            filePath={videos[index].filePath}
            mouseDown={mouseDown}
            albumID={videos[index].album}
            viewAlbum={() => {
              viewAlbum(videos[index].album);
              setCurrentMedia("album");
            }}
            requestScrollToVideos={requestScrollToVideos}
            requestVideoPlay={requestVideoPlay}
          />
          {complete && viewAll ? (
            <div id="next-videos">
              <div className="all-videos">
                {videos.map((video, index) => (
                  <Video
                    key={index}
                    album={
                      albums.filter((album) => album.id === video.album)[0]
                    }
                    playingType={playingType}
                    setPlayingType={setPlayingType}
                    setVideoM={setVideoM}
                    setPlaying={setPlaying}
                    id={`next_video_${index}`}
                    videoID={video.id}
                    videos={videos}
                    playingIndex={index}
                    index={index}
                    setIndex={setIndex}
                    name={video.title}
                    image={video.thumbnail}
                    preview={video.filePath}
                    toMinutes={toMinutes}
                    viewAlbum={() => {
                      viewAlbum(video.album);
                      setCurrentMedia("album");
                    }}
                    viewAll={viewAll}
                  />
                ))}
              </div>
            </div>
          ) : (
            <NextVideos
              albums={albums}
              playingType={playingType}
              setPlayingType={setPlayingType}
              setVideoM={setVideoM}
              setPlaying={setPlaying}
              videos={videos}
              index={index}
              setIndex={setIndex}
              toMinutes={toMinutes}
              viewAlbum={viewAlbum}
              setCurrentMedia={setCurrentMedia}
            />
          )}
        </>
      ) : (
        <>
          <div className="playing-video-loader">
            <div>
              <div>
                <div className="loading-title"></div>
                <div className="loading-album"></div>
              </div>
              <div className="loading-copyright"></div>
            </div>
            <div className="loading-main-video"></div>
            <div>
              <div className="loading-icons"></div>
              <div className="loading-icons"></div>
            </div>
            <div className="loading-description"></div>
          </div>
          <div className="video-loader">
            <div className="loading-video">
              <div className="loading-video_image"></div>
              <div className="loading-video_title"></div>
              <div className="loading-video_composer"></div>
            </div>
            <div className="loading-video">
              <div className="loading-video_image"></div>
              <div className="loading-video_title"></div>
              <div className="loading-video_composer"></div>
            </div>
            <div className="loading-video">
              <div className="loading-video_image"></div>
              <div className="loading-video_title"></div>
              <div className="loading-video_composer"></div>
            </div>
            <div className="loading-video">
              <div className="loading-video_image"></div>
              <div className="loading-video_title"></div>
              <div className="loading-video_composer"></div>
            </div>
          </div>
        </>
      )}
    </section>
  );
}
