import React, { useEffect, useRef, useState } from "react";
import { Splide } from "@splidejs/react-splide";
import Testimonial from "./Testimonial";
import "@splidejs/react-splide/css";
import "./styles/Testimonials.css";

export default function Testimonials({ testimonialsActive }) {
  const testimonialsRef = useRef();
  const [splide, setSplide] = useState(undefined);
  const [Autoplay, setAutoplay] = useState(undefined);

  useEffect(() => {
    if (testimonialsRef.current) {
      setSplide(testimonialsRef.current.splide);
      if (splide) {
        setAutoplay(splide.Components.Autoplay);
        if (splide && Autoplay) {
          const testimonials = document.querySelector(".testimonials");

          let startTime = 0,
            currentTime = 0,
            endTime = 0,
            interval = 10000;

          const timerStart = () => {
            startTime = Date.now();
            endTime = startTime;
          };

          const timerRun = () => {
            currentTime = Date.now();

            if (currentTime < endTime + interval) {
              testimonials.setAttribute(
                "style",
                `--width: ${(currentTime - startTime) / (interval / 100)}%`
              );
              window.requestAnimationFrame(timerRun);
            } else {
              testimonials.setAttribute("style", "--width: 0");
              timerStop();
            }
          };

          const timerStop = () => {
            startTime = 0;
            currentTime = 0;
            endTime = 0;
          };

          const autoplayPause = () => {
            timerStop();
            timerRun();
            Autoplay.pause();
          };

          const autoplayPlay = () => {
            if (
              document.hasFocus() &&
              document.querySelector(".testimonials:hover") == null
            ) {
              timerStart();
              timerRun();
              if (Autoplay.isPaused()) Autoplay.play();
              interval = 10000;
            }
          };

          splide.on("autoplay:play", () => {
            timerStart();
            timerRun();
          });

          splide.on("autoplay:paused", () => {
            timerStop();
            timerRun();
          });

          splide.on("moved", () => {
            if (!Autoplay.isPaused()) {
              timerStart();
              timerRun();
              interval = 9200;
            }
          });

          if (Autoplay.isPaused()) Autoplay.play();

          testimonials.addEventListener("mouseover", autoplayPause);
          testimonials.addEventListener("mouseout", autoplayPlay);

          window.addEventListener("blur", autoplayPause);
          window.addEventListener("focus", autoplayPlay);

          const carousel = document.querySelector("#splide02-track");
          const minDrag = 20;
          let startX = 0;

          const dragStart = (e) => {
            startX = e.clientX;
          };

          let wait = false;

          const dragEnd = (e) => {
            if (!wait) {
              wait = true;

              const endX = e.clientX;
              const offset = endX - startX;

              if (Math.abs(offset) >= minDrag) {
                if (offset > 0) {
                  splide.go("<");
                } else {
                  splide.go(">");
                }
              }

              setTimeout(() => (wait = false), 400);
            }

            startX = 0;
          };

          carousel.addEventListener("mousedown", (e) => dragStart(e));
          carousel.addEventListener("mouseup", (e) => dragEnd(e));
        }
      }
    }
  }, [splide, Autoplay, testimonialsRef]);

  useEffect(() => {
    if (splide && Autoplay) {
      if (testimonialsActive) {
        setTimeout(() => Autoplay.play(), 1700);
        Autoplay.pause();
        splide.go(0);
        splide.refresh();
        document.querySelector("#splide02-list").style.transform =
          "translateX(-2436px)";
      } else {
        setTimeout(() => {
          Autoplay.pause();
          splide.go(0);
          splide.refresh();
          document.querySelector("#splide02-list").style.transform =
            "translateX(-2436px)";
        }, 500);
      }
    }
  }, [splide, Autoplay, testimonialsActive]);

  return (
    <div className="testimonials">
      <button className="testimonials-arrow left" disabled>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="66"
          viewBox="0 0 35 66"
          fill="none"
          stroke="#dddddd"
        >
          <path d="M34 65L2 33L34 1" strokeWidth="2" />
        </svg>
      </button>
      <Splide
        ref={testimonialsRef}
        options={{
          type: "loop",
          speed: 800,
          width: 1198,
          perPage: 4,
          focus: 0,
          gap: 20,
          pagination: true,
          arrows: false,
          cover: false,
          drag: false,
          waitForTransition: false,
          autoplay: false,
          interval: 10000,
          pauseOnHover: false,
        }}
      >
        <Testimonial
          name="NAME"
          email="email@email.com"
          occupation="OCCUPATION"
          date="00/00/0000"
          rating={5}
        >
          JVKomplete was a pleasure to work with! His attention to detail and
          ability to bring my ideas to life resulted in a composition beyond my
          expectations. Highly recommended!
        </Testimonial>
        <Testimonial
          name="NAME"
          email="email@email.com"
          occupation="OCCUPATION"
          date="00/00/0000"
          rating={2.5}
        >
          Working with JVKomplete was an excellent experience! He created a
          beautiful composition that perfectly captured my vision while
          maintaining clear communication throughout the process. His
          professionalism and creativity made everything smooth and stress-free.
          I highly recommend his work!
        </Testimonial>
        <Testimonial
          name="NAME"
          email="email@email.com"
          occupation="OCCUPATION"
          date="00/00/0000"
          rating={4}
        >
          I'm thrilled with the work JVKomplete delivered! His creativity and
          professionalism made the entire process smooth.
        </Testimonial>
        <Testimonial
          name="NAME"
          email="email@email.com"
          occupation="OCCUPATION"
          date="00/00/0000"
          rating={4.5}
        >
          JVKomplete exceeded all expectations!
        </Testimonial>
        <Testimonial
          name="NAME"
          email="email@email.com"
          occupation="OCCUPATION"
          date="00/00/0000"
          rating={0}
        >
	 This was great awesome service. I will use him again soon!
        </Testimonial>
        <Testimonial
          name="NAME"
          email="email@email.com"
          occupation="OCCUPATION"
          date="00/00/0000"
          rating={1}
        >
          COMING SOON!
        </Testimonial>
        <Testimonial
          name="NAME"
          email="email@email.com"
          occupation="OCCUPATION"
          date="00/00/0000"
          rating={0}
        >
          COMING SOON!
        </Testimonial>
        <Testimonial
          name="NAME"
          email="email@email.com"
          occupation="OCCUPATION"
          date="00/00/0000"
          rating={0}
        >
          COMING SOON!
        </Testimonial>
      </Splide>
      <button className="testimonials-arrow right" disabled>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="66"
          viewBox="0 0 35 66"
          fill="none"
          stroke="#dddddd"
        >
          <path d="M1 1L33 33L1 65" strokeWidth="2" />
        </svg>
      </button>
    </div>
  );
}
