import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import Home from "./pages/Home";
import Legal from "./pages/Legal";
import Media from "./pages/Media";
import Error404 from "./error_pages/Error404";
import "./index.css";

export default function App() {
  const [path, setPath] = useState(window.location.pathname);
  const page = new URLSearchParams(window.location.search.toLowerCase()).get(
    ""
  );

  if (
    path === "/" ||
    ((path === "/about" || path === "/about/") &&
      (page === "main" ||
        page === "faq" ||
        page === "news" ||
        page === "testimonials")) ||
    ((path === "/home" || path === "/home/") && page === "contact")
  ) {
    return <Home path={path} page={page} />;
  } else if (path === "/legal" || path === "/legal/") {
    return <Legal />;
  } else if (path === "/media" || path === "/media/") {
    return <Media path={path} setPath={setPath} page={page} />;
  } else {
    return <Error404 />;
  }
}

ReactDOM.createRoot(document.getElementById("root")).render(<App />);
