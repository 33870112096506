import React, { useEffect } from "react";
import MediaCarousel from "../MediaCarousel.jsx";
import { SplideSlide } from "@splidejs/react-splide";
import Image from "../Image.jsx";
import MediaHeading from "../MediaHeading.jsx";
import "./styles/Gallery.css";

export default function Images({
  images,
  id,
  albums,
  viewAlbum,
  setCurrentMedia,
  setGalleryIndex,
  setGalleryOpen,
  requestScrollToGallery,
  complete,
  viewAll,
  setViewAll,
}) {
  useEffect(() => {
    if (requestScrollToGallery) {
      window.scrollTo(
        0,
        document.querySelector("#album-gallery").getBoundingClientRect().top -
          10
      );
    }
  }, [requestScrollToGallery]);

  return (
    <div id="album-gallery">
      <MediaHeading
        href={`/media?id=a${(id + 1).toString().padStart(2, "0")}&gallery`}
        link={{
          url:
            complete && viewAll
              ? `/media?id=a${(albums.find((album) => album.complete).id + 1)
                  .toString()
                  .padStart(2, "0")}&gallery`
              : `/media?id=a${(albums.find((album) => album.complete).id + 1)
                  .toString()
                  .padStart(2, "0")}&gallery&view-all`,
          text: complete && viewAll ? "VIEW LESS" : "VIEW ALL",
          action: (e) => {
            e.preventDefault();
            window.history.pushState(
              "",
              "",
              complete && viewAll
                ? `/media?id=a${(albums.find((album) => album.complete).id + 1)
                    .toString()
                    .padStart(2, "0")}&gallery`
                : `/media?id=a${(albums.find((album) => album.complete).id + 1)
                    .toString()
                    .padStart(2, "0")}&gallery&view-all`
            );
            if (complete) {
              setViewAll(!viewAll);
            } else {
              setViewAll(true);
              viewAlbum(albums.find((album) => album.complete).id);
              setCurrentMedia("album");
            }
            setTimeout(() => {
              window.scrollBy(
                0,
                document.querySelector("#album-gallery").getBoundingClientRect()
                  .top - 10
              );
            }, 100);
          },
        }}
      >
        GALLERY
      </MediaHeading>
      <div className="gallery-slider">
        {images[0] !== "IMAGES_NOT_YET_LOADED" &&
        albums[0] !== "ALBUMS_NOT_YET_LOADED" &&
        images.length !== 0 ? (
          complete && viewAll ? (
            <div className="all-images">
              {images.map((image, index) => (
                <Image
                  id={image.id}
                  title={image.title}
                  album={image.album}
                  thumbnail={image.thumbnail}
                  albums={albums}
                  viewAlbum={viewAlbum}
                  setCurrentMedia={setCurrentMedia}
                  index={index}
                  length={images.length}
                  openImage={() => {
                    window.scrollBy(
                      0,
                      document
                        .querySelector("#album-gallery")
                        .getBoundingClientRect().top - 10
                    );
                    setGalleryIndex(image.id);
                    setGalleryOpen(true);
                  }}
                  images={images}
                />
              ))}
            </div>
          ) : (
            <MediaCarousel>
              {images.map((image, index) => (
                <SplideSlide>
                  <Image
                    id={image.id}
                    title={image.title}
                    album={image.album}
                    thumbnail={image.thumbnail}
                    albums={albums}
                    viewAlbum={viewAlbum}
                    setCurrentMedia={setCurrentMedia}
                    index={index}
                    length={images.length}
                    openImage={() => {
                      window.scrollBy(
                        0,
                        document
                          .querySelector("#album-gallery")
                          .getBoundingClientRect().top - 10
                      );
                      setGalleryIndex(image.id);
                      setGalleryOpen(true);
                    }}
                    images={images}
                  />
                </SplideSlide>
              ))}
            </MediaCarousel>
          )
        ) : (
          <span>loading...</span>
        )}
      </div>
    </div>
  );
}
