import React, { useState, useEffect } from "react";
import share from "./Share";
import "./styles/AudioCollection.css";

const transitionAuto = require("@alexspirgel/transition-auto");

export function AudioCollection({ children }) {
  return (
    <table className="audio-collection">
      <colgroup>
        <col className="audio_track-number" />
        <col className="audio_track-title" />
        <col className="audio_track-album" />
        <col className="audio_track-mood" />
        <col className="audio_track-duration" />
        <col className="audio_track-rating" />
        <col className="audio_track-share-options" />
      </colgroup>
      <thead>
        <tr>
          <th>
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              stroke="currentColor"
            >
              <path d="M0 3H10M0 7H10M3 0V10M7 0V10" strokeWidth="2" />
            </svg>
          </th>
          <th>TITLE</th>
          <th>ALBUM</th>
          <th>MOOD</th>
          <th>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
              <path d="M7.992 0C3.576 0 0 3.584 0 8C0 12.416 3.576 16 7.992 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 7.992 0ZM8 14.4C4.464 14.4 1.6 11.536 1.6 8C1.6 4.464 4.464 1.6 8 1.6C11.536 1.6 14.4 4.464 14.4 8C14.4 11.536 11.536 14.4 8 14.4Z" />
              <path d="M8.4 4H7.2V8.8L11.4 11.32L12 10.336L8.4 8.2V4Z" />
            </svg>
          </th>
          <th>RATINGS</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{children ? children : "NO TRACKS AVAILABLE"}</tbody>
    </table>
  );
}

export function AudioTrack({
  id,
  index,
  title,
  album,
  mood,
  duration,
  rating,
  openPlayer,
  playing,
  setPlaying,
  currentPlaying,
  setCurrentPlaying,
  list,
  setList,
  tracks,
  albumID,
  viewAlbum,
  date,
  views,
  description,
  copyright,
}) {
  const [userRating, setUserRating] = useState("UNRATED");
  const [positiveRating, setPositiveRating] = useState(rating.positive);
  const [negativeRating, setNegativeRating] = useState(rating.negative);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [wait, setWait] = useState(false);

  function uploadDate() {
    const dateString = new Date(date);
    const dateDay = dateString.getDate();
    const day =
      dateDay.toString() +
      (dateDay === 1 || dateDay === 21 || dateDay === 31
        ? "st"
        : dateDay === 2 || dateDay === 22
        ? "nd"
        : dateDay === 3 || dateDay === 23
        ? "rd"
        : "th");
    const month = dateString.toLocaleString("default", { month: "long" });
    const year = dateString.getFullYear().toString();

    return `${month} ${day}, ${year}`.toUpperCase();
  }

  useEffect(() => {
    const description = document.querySelector(
      `.audio-collection .description[data-id="${id}"] .outer`
    );

    if (descriptionOpen === true) {
      setTimeout(() => (description.style.height = "auto"), 400);

      transitionAuto({
        element: description,
        property: "height",
        value: "auto",
      });
    } else {
      transitionAuto({
        element: description,
        property: "height",
        value: "0px",
      });
      setTimeout(() => {
        description.style.height = "0px";
      }, 200);
    }
  }, [id, descriptionOpen]);

  function play_pause(e) {
    document.querySelector("#player").focus();
    window.scrollBy(
      0,
      e.target.closest("section").getBoundingClientRect().top - 10
    );
    setList(list);
    setCurrentPlaying(id);
    setPlaying(!playing || currentPlaying !== id);
    openPlayer();
  }

  function thumbsUp() {
    if (userRating === "POSITIVE") {
      setUserRating("UNRATED");
      setPositiveRating(rating.positive);
      setNegativeRating(rating.negative);
    } else {
      setUserRating("POSITIVE");
      setPositiveRating(rating.positive + 1);
      setNegativeRating(rating.negative);
    }
  }

  function thumbsDown() {
    if (userRating === "NEGATIVE") {
      setUserRating("UNRATED");
      setPositiveRating(rating.positive);
      setNegativeRating(rating.negative);
    } else {
      setUserRating("NEGATIVE");
      setPositiveRating(rating.positive);
      setNegativeRating(rating.negative + 1);
    }
  }

  function options() {
    if (menuOpen) {
      setMenuOpen(false);
      document.body.onmousedown = () => setMenuOpened(false);
    } else {
      setMenuOpen(true);
      setMenuOpened(true);
      document.body.onmousedown = () => {
        setMenuOpen(false);
        document.body.onmousedown = () => setMenuOpened(false);
      };
    }
  }

  return (
    <>
      <tr data-selected={currentPlaying === id} onDoubleClick={play_pause}>
        <td>
          <span
            style={currentPlaying === id && !playing ? { opacity: "0" } : {}}
          >
            {currentPlaying === id && playing ? (
              <img
                src="/images/media-icon-equalizer.gif"
                width="16"
                height="16"
                alt="equalizer"
              />
            ) : (
              index
            )}
          </span>
          <button
            style={currentPlaying === id && !playing ? { opacity: "1" } : {}}
            className="audio_track-play-pause"
            data-playing={currentPlaying === id && playing}
            title="Play"
            onClick={(e) => {
              e.target.closest("button").blur();
              play_pause(e);
            }}
          >
            <svg width="11" height="14" viewBox="0 0 11 14" fill="currentColor">
              <path />
              <path />
            </svg>
          </button>
        </td>
        <td>
          <a
            href={`/media?id=a${(albumID + 1).toString().padStart(2, "0")}t${(
              tracks
                .filter((track) => track.album === albumID)
                .map((track) => track.id)
                .indexOf(id) + 1
            )
              .toString()
              .padStart(2, "0")}`}
            onClick={(e) => e.preventDefault()}
            style={{ display: "contents" }}
          >
            <button className="track-title">{title}</button>
          </a>
        </td>
        <td>
          <a
            href={`/media?id=a${(albumID + 1).toString().padStart(2, "0")}`}
            onClick={(e) => e.preventDefault()}
            style={{ display: "contents" }}
          >
            <button
              className="track-album"
              onDoubleClick={(e) => e.stopPropagation()}
              onClick={() => {
                viewAlbum();
                window.history.pushState(
                  "",
                  "",
                  `/media?id=a${(albumID + 1).toString().padStart(2, "0")}`
                );
              }}
            >
              {album}
            </button>
          </a>
        </td>
        <td>
          <span onDoubleClick={(e) => e.stopPropagation()}>{mood}</span>
        </td>
        <td>
          <span onDoubleClick={(e) => e.stopPropagation()}>{duration}</span>
        </td>
        <td>
          <label
            className="audio_track-positive"
            onDoubleClick={(e) => e.stopPropagation()}
            style={userRating === "POSITIVE" ? { color: "#dddddd" } : null}
          >
            <button
              className="audio_track-thumbs-up"
              onClick={thumbsUp}
              data-title="Like"
            >
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill={userRating === "POSITIVE" ? "#3d5974" : "currentColor"}
              >
                <path d="M9.58 0L4.36 5V14H13.36L16 8V4.9H10L10.81 1.13L9.58 0ZM0 5.6H2.91V14H0V5.6Z" />
              </svg>
            </button>
            <span>{positiveRating}</span>
          </label>
          <label
            className="audio_track-negative"
            onDoubleClick={(e) => e.stopPropagation()}
            style={userRating === "NEGATIVE" ? { color: "#dddddd" } : null}
          >
            <span>{negativeRating}</span>
            <button
              className="audio_track-thumbs-down"
              onClick={thumbsDown}
              data-title="Dislike"
            >
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill={userRating === "NEGATIVE" ? "#743d3d" : "currentColor"}
              >
                <path d="M6.42 14L11.64 9V0H2.64L0 6V9.1H6L5.19 12.87L6.42 14ZM13.09 0H16V8.4H13.09V0Z" />
              </svg>
            </button>
          </label>
        </td>
        <td>
          <button
            className="audio_track-description"
            onClick={(e) => {
              if (!wait) {
                setWait(true);
                setTimeout(() => setWait(false), 400);
                setDescriptionOpen(!descriptionOpen);
                window.scrollBy(
                  0,
                  e.target.closest("section").getBoundingClientRect().top - 10
                );
              }
            }}
            onDoubleClick={(e) => e.stopPropagation()}
            title={descriptionOpen ? "Minimize" : "Expand"}
          >
            <svg
              height="12"
              width="12"
              viewBox="0 0 10 10"
              style={{
                transition: "transform 0.4s",
                transform: `rotate(${descriptionOpen ? 225 : 0}deg) scale(${
                  descriptionOpen ? 1.2 : 1
                })`,
              }}
            >
              <path
                d="M10 5.71429H5.71429V10H4.28571V5.71429H0V4.28571H4.28571V0H5.71429V4.28571H10V5.71429Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <button
            className="audio_track-share"
            onDoubleClick={(e) => e.stopPropagation()}
            onClick={() =>
              share(
                `/media?id=a${(albumID + 1).toString().padStart(2, "0")}t${(
                  tracks
                    .filter((track) => track.album === albumID)
                    .map((track) => track.id)
                    .indexOf(id) + 1
                )
                  .toString()
                  .padStart(2, "0")}`
              )
            }
            title="Share"
          >
            <svg width="12" height="14" viewBox="0 0 12 14" fill="currentColor">
              <path d="M10 9.38667C9.49333 9.38667 9.04 9.58667 8.69333 9.9L3.94 7.13333C3.97333 6.98 4 6.82667 4 6.66667C4 6.50667 3.97333 6.35333 3.94 6.2L8.64 3.46C9 3.79333 9.47333 4 10 4C11.1067 4 12 3.10667 12 2C12 0.893333 11.1067 0 10 0C8.89333 0 8 0.893333 8 2C8 2.16 8.02667 2.31333 8.06 2.46667L3.36 5.20667C3 4.87333 2.52667 4.66667 2 4.66667C0.893333 4.66667 0 5.56 0 6.66667C0 7.77333 0.893333 8.66667 2 8.66667C2.52667 8.66667 3 8.46 3.36 8.12667L8.10667 10.9C8.07333 11.04 8.05333 11.1867 8.05333 11.3333C8.05333 12.4067 8.92667 13.28 10 13.28C11.0733 13.28 11.9467 12.4067 11.9467 11.3333C11.9467 10.26 11.0733 9.38667 10 9.38667Z" />
            </svg>
          </button>
          <button
            className="audio_track-options"
            onDoubleClick={(e) => e.stopPropagation()}
            onMouseUp={!menuOpened ? options : null}
            title="Options"
          >
            <svg width="4" height="16" viewBox="0 0 4 16" fill="currentColor">
              <path d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z" />
            </svg>
          </button>
          <div className="menu" data-open={menuOpen}>
            <a
              href={`/media?id=a${(albumID + 1).toString().padStart(2, "0")}`}
              onClick={(e) => e.preventDefault()}
              style={{ display: "contents" }}
            >
              <button
                onMouseDown={() => {
                  viewAlbum();
                  window.history.pushState(
                    "",
                    "",
                    `/media?id=a${(albumID + 1).toString().padStart(2, "0")}`
                  );
                }}
              >
                <span>DETAILS</span>
                <svg height="10" viewBox="0 0 8 12" fill="#DDDDDD">
                  <path d="M1.41 0L0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0Z" />
                </svg>
              </button>
            </a>
            <button>
              <span>FEATURED</span>
              <svg height="10" viewBox="0 0 8 12" fill="#DDDDDD">
                <path d="M1.41 0L0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0Z" />
              </svg>
            </button>
            <button>
              <span>PURCHASE</span>
              <svg height="10" viewBox="0 0 8 12" fill="#DDDDDD">
                <path d="M1.41 0L0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0Z" />
              </svg>
            </button>
            <button
              onMouseDown={() =>
                share(
                  `/media?id=a${(albumID + 1).toString().padStart(2, "0")}t${(
                    tracks
                      .filter((track) => track.album === albumID)
                      .map((track) => track.id)
                      .indexOf(id) + 1
                  )
                    .toString()
                    .padStart(2, "0")}`
                )
              }
            >
              <span>SHARE</span>
              <svg height="10" viewBox="0 0 8 12" fill="#DDDDDD">
                <path d="M1.41 0L0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0Z" />
              </svg>
            </button>
            <div
              style={{
                width: "auto",
                height: "1px",
                margin: "10px 20px",
                background: "#808080",
              }}
            ></div>
            <button
              style={
                userRating === "POSITIVE"
                  ? { backgroundColor: "#3d5974" }
                  : null
              }
              onMouseDown={(e) => {
                e.stopPropagation();
                thumbsUp();
              }}
            >
              LIKE
            </button>
            <button
              style={
                userRating === "NEGATIVE"
                  ? { backgroundColor: "#743d3d" }
                  : null
              }
              onMouseDown={(e) => {
                e.stopPropagation();
                thumbsDown();
              }}
            >
              DISLIKE
            </button>
          </div>
        </td>
      </tr>
      <tr>
        <td className="description" data-id={id} colSpan="7">
          <div className="outer">
            <div className="container">
              <div className="content">
                <div className="top">
                  {uploadDate()} •{" "}
                  {views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} VIEWS
                </div>
                <p>{description}</p>
                <div className="social-media-icons">
                  <div className="social-media-img">
                    <a
                      href="https://facebook.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        draggable="false"
                        title="Facebook"
                        alt="facebook"
                        src="/images/connect-facebook.png"
                      />
                    </a>
                  </div>
                  <div className="social-media-img">
                    <a href="https://x.com" target="_blank" rel="noreferrer">
                      <img
                        draggable="false"
                        title="X"
                        alt="x"
                        src="/images/connect-x.png"
                      />
                    </a>
                  </div>
                  <div className="social-media-img">
                    <a
                      href="https://youtube.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        draggable="false"
                        title="YouTube"
                        alt="youtube"
                        src="/images/connect-youtube.png"
                      />
                    </a>
                  </div>
                  <div className="social-media-img">
                    <a
                      href="https://rumble.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        draggable="false"
                        title="Rumble"
                        alt="rumble"
                        src="/images/connect-rumble.png"
                      />
                    </a>
                  </div>
                  <div className="social-media-img">
                    <a
                      href="https://twitch.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        draggable="false"
                        title="Twitch"
                        alt="twitch"
                        src="/images/connect-twitch.png"
                      />
                    </a>
                  </div>
                  <div className="social-media-img">
                    <a
                      href="https://soundcloud.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        draggable="false"
                        title="SoundCloud"
                        alt="soundcloud"
                        src="/images/connect-soundcloud.png"
                      />
                    </a>
                  </div>
                  <div className="social-media-img">
                    <a
                      href="https://patreon.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        draggable="false"
                        title="Patreon"
                        alt="patreon"
                        src="/images/connect-patreon.png"
                      />
                    </a>
                  </div>
                  <div className="social-media-img">
                    <a
                      href="https://discord.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        draggable="false"
                        title="Discord"
                        alt="discord"
                        src="/images/connect-discord.png"
                      />
                    </a>
                  </div>
                </div>
                <span
                  className="copyright"
                  dangerouslySetInnerHTML={{ __html: copyright }}
                ></span>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}
