import React from "react";
import "./styles/Share.css";

export function CopiedMessage() {
  return (
    <span id="copy-message">COPIED TO CLIPBOARD</span>
  )
}

export default function share(text) {
  window.navigator.clipboard.writeText(window.location.host + text);
  const message = document.querySelector("#copy-message");
  message.style.opacity = "1";
  setTimeout(() => message.style.opacity = "0", 2400);
}