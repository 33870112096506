import React, { useEffect, useState } from "react";
import MediaHeading from "./MediaHeading";
import { SplideSlide } from "@splidejs/react-splide";
import MediaCarousel from "./MediaCarousel.jsx";
import Album from "./Album.jsx";
import "./styles/Albums.css";

export default function Albums({
  albums,
  viewAlbum,
  setCurrentMedia,
  toMinutes,
  tracks,
  currentPlaying,
  setList,
  setCurrentPlaying,
  setPlayingType,
  setPlaying,
  openPlayer,
  setViewAllVideos,
  setViewAllImages,
}) {
  const [viewAll, setViewAll] = useState(false);

  useEffect(() => {
    window.addEventListener("popstate", () => {
      const page = new URLSearchParams(
        window.location.search.toLowerCase()
      ).get("view-all");

      if (page === null) {
        setViewAll(false);
      } else {
        setViewAll(true);
      }
    });
  }, []);

  return (
    <section id="albums">
      <MediaHeading
        href="/media?=albums&albums"
        link={{
          url: viewAll ? "/media?=albums" : "/media?=albums&view-all",
          text: viewAll ? "VIEW LESS" : "VIEW ALL",
          action: (e) => {
            e.preventDefault();
            window.history.pushState(
              "",
              "",
              viewAll ? "/media?=albums" : "/media?=albums&view-all"
            );
            setViewAll(!viewAll);
          },
        }}
      >
        ALBUMS
      </MediaHeading>
      <div className="content">
        <h2 className="subtitle">PERSONAL</h2>
        {viewAll ? (
          <div className="all-albums">
            {albums.map((album) => (
              <Album
                id={album.id}
                name={album.title}
                mediumImage={album.mediumImagePath}
                image={album.imagePath}
                composer={album.composer}
                complete={album.complete}
                height="big"
                viewAlbum={() => {
                  viewAlbum(album.id);
                  setCurrentMedia("album");
                }}
                toMinutes={toMinutes}
                tracks={tracks}
                currentPlaying={currentPlaying}
                setList={setList}
                setCurrentPlaying={setCurrentPlaying}
                setPlayingType={setPlayingType}
                setPlaying={setPlaying}
                openPlayer={openPlayer}
                setViewAllVideos={setViewAllVideos}
                setViewAllImages={setViewAllImages}
              />
            ))}
          </div>
        ) : (
          <MediaCarousel
            activeSlide={
              currentPlaying || currentPlaying === 0
                ? albums.findIndex(
                    (album) =>
                      album.id ===
                      tracks.find((track) => track.id === currentPlaying).album
                  )
                : 0
            }
          >
            {albums.map((album) => (
              <SplideSlide>
                <Album
                  id={album.id}
                  name={album.title}
                  mediumImage={album.mediumImagePath}
                  image={album.imagePath}
                  composer={album.composer}
                  complete={album.complete}
                  height="big"
                  viewAlbum={() => {
                    viewAlbum(album.id);
                    setCurrentMedia("album");
                  }}
                  toMinutes={toMinutes}
                  tracks={tracks}
                  currentPlaying={currentPlaying}
                  setList={setList}
                  setCurrentPlaying={setCurrentPlaying}
                  setPlayingType={setPlayingType}
                  setPlaying={setPlaying}
                  openPlayer={openPlayer}
                  setViewAllVideos={setViewAllVideos}
                  setViewAllImages={setViewAllImages}
                />
              </SplideSlide>
            ))}
          </MediaCarousel>
        )}
        <h2 className="subtitle">PROJECTS</h2>
        {viewAll ? (
          <div className="all-albums">
            {albums.map((album) => (
              <Album
                id={album.id}
                name={album.title}
                mediumImage={album.mediumImagePath}
                image={album.imagePath}
                composer={album.composer}
                complete={album.complete}
                height="small"
                viewAlbum={() => {
                  viewAlbum(album.id);
                  setCurrentMedia("album");
                }}
                toMinutes={toMinutes}
                tracks={tracks}
                currentPlaying={currentPlaying}
                setList={setList}
                setCurrentPlaying={setCurrentPlaying}
                setPlayingType={setPlayingType}
                setPlaying={setPlaying}
                openPlayer={openPlayer}
                setViewAllVideos={setViewAllVideos}
                setViewAllImages={setViewAllImages}
              />
            ))}
          </div>
        ) : (
          <MediaCarousel
            activeSlide={
              currentPlaying || currentPlaying === 0
                ? albums.findIndex(
                    (album) =>
                      album.id ===
                      tracks.find((track) => track.id === currentPlaying).album
                  )
                : 0
            }
          >
            {albums.map((album) => (
              <SplideSlide>
                <Album
                  id={album.id}
                  name={album.title}
                  mediumImage={album.mediumImagePath}
                  image={album.imagePath}
                  composer={album.composer}
                  complete={album.complete}
                  height="small"
                  viewAlbum={() => {
                    viewAlbum(album.id);
                    setCurrentMedia("album");
                  }}
                  toMinutes={toMinutes}
                  tracks={tracks}
                  currentPlaying={currentPlaying}
                  setList={setList}
                  setCurrentPlaying={setCurrentPlaying}
                  setPlayingType={setPlayingType}
                  setPlaying={setPlaying}
                  openPlayer={openPlayer}
                  setViewAllVideos={setViewAllVideos}
                  setViewAllImages={setViewAllImages}
                />
              </SplideSlide>
            ))}
          </MediaCarousel>
        )}
        <h2 className="subtitle">WORK IN PROGRESS</h2>
        {viewAll ? (
          <div className="all-albums">
            {albums.map((album) => (
              <Album
                id={album.id}
                name={album.title}
                mediumImage={album.mediumImagePath}
                image={album.imagePath}
                composer={album.composer}
                complete={album.complete}
                height="small"
                viewAlbum={() => {
                  viewAlbum(album.id);
                  setCurrentMedia("album");
                }}
                toMinutes={toMinutes}
                tracks={tracks}
                currentPlaying={currentPlaying}
                setList={setList}
                setCurrentPlaying={setCurrentPlaying}
                setPlayingType={setPlayingType}
                setPlaying={setPlaying}
                openPlayer={openPlayer}
                setViewAllVideos={setViewAllVideos}
                setViewAllImages={setViewAllImages}
              />
            ))}
          </div>
        ) : (
          <MediaCarousel
            activeSlide={
              currentPlaying || currentPlaying === 0
                ? albums.findIndex(
                    (album) =>
                      album.id ===
                      tracks.find((track) => track.id === currentPlaying).album
                  )
                : 0
            }
          >
            {albums.map((album) => (
              <SplideSlide>
                <Album
                  id={album.id}
                  name={album.title}
                  mediumImage={album.mediumImagePath}
                  image={album.imagePath}
                  composer={album.composer}
                  complete={album.complete}
                  height="small"
                  viewAlbum={() => {
                    viewAlbum(album.id);
                    setCurrentMedia("album");
                  }}
                  toMinutes={toMinutes}
                  tracks={tracks}
                  currentPlaying={currentPlaying}
                  setList={setList}
                  setCurrentPlaying={setCurrentPlaying}
                  setPlayingType={setPlayingType}
                  setPlaying={setPlaying}
                  openPlayer={openPlayer}
                  setViewAllVideos={setViewAllVideos}
                  setViewAllImages={setViewAllImages}
                />
              </SplideSlide>
            ))}
          </MediaCarousel>
        )}
      </div>
    </section>
  );
}
